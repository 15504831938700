'use strict'

var base = module.superModule;

$('#submitNewsletter').click(function(e) {
    e.preventDefault();
    if (!$('#nameNewsletter').val()) {
        $('#nameNewsletter').addClass('is-invalid').siblings('.invalid-feedback').html('Preencha esse campo');
    } else {
        $('#nameNewsletter').removeClass('is-invalid').siblings('.invalid-feedback').html('');
    }
    if (!$('#emailNewsletter').val()) {
        $('#emailNewsletter').addClass('is-invalid').siblings('.invalid-feedback').html('Preencha esse campo');
    } else if ($('#emailNewsletter').val().indexOf('@') < 0) {
        $('#emailNewsletter').addClass('is-invalid').siblings('.invalid-feedback').html('Inclua um "@" no endereço de e-mail. "' +  $('#emailNewsletter').val() + '" está com um "@" faltando.');
    } else {
        $('#emailNewsletter').removeClass('is-invalid').siblings('.invalid-feedback').html('');
    }

    if ($('#nameNewsletter').val() && $('#emailNewsletter').val().indexOf('@') > -1) {
        $('#newsletterForm').spinner().start();
        const jqXHR = $.ajax({
            url: window.Urls.newsletterAddMember,
            data: {
                name: $('#nameNewsletter').val(),
                email: $('#emailNewsletter').val()
            }
        });

        jqXHR.done(function (response) {
            $('#newsletterForm').spinner().stop();
            $('#nameNewsletter').val('');
            $('#emailNewsletter').val('');

            if (response.erro) {
                if (response.errorMsg === 'MemberExists') {
                    $('.alert-message').removeClass('d-none');
                    $('.alert-message p').text(window.Resources.YET_REGISTERED_NEWSLETTER);
                } else {
                    $('.alert-message').removeClass('d-none');
                    $('.alert-message p').text(window.Resources.ERROR_REGISTERED_NEWSLETTER);
                }
            } else {
                $('.alert-message').removeClass('d-none');
                $('.alert-message p').text(window.Resources.SUCCESS_REGISTERED_NEWSLETTER);
            }
        });

        jqXHR.error(function () {
            $('#newsletterForm').spinner().stop();
            $('#nameNewsletter').val('');
            $('#emailNewsletter').val('');
        });
    }
});

module.exports = base;
